import { Button, Input, Select, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
// import { SIS_STORAGE, STUDENT_PROGRAMS_LIST } from "../../../apis/apis";
import { useNavigate } from "react-router-dom";
import { CloseOutlined, CloudUploadOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { ALL_SEMESTERS_LIST, GET_DEMOGRAPHIC_INFORMATION, SIS_STORAGE, STUDENT_PROGRAMS_LIST } from "../../../../apis/apis";
import SisLoader from "../../../../widgets/loader";



const DemographicInformationPreview = () => {

    const navigate = useNavigate();
    const [loader, set_loader] = useState(true);
    const [identify, set_identify] = useState("");
    const [applicant_status, set_applicant_status] = useState("");
    const [veteran, set_veteran] = useState("");
    const [starting_semester, set_starting_semester] = useState("");
    const [starting_cohort, set_starting_cohort] = useState("");
    const [program_format, set_program_format] = useState("");
    const [transfer_in_student, set_transfer_in_student] = useState("");
    const [recruiter, set_recruiter] = useState("");
    const [programme_intended, set_programme_intended] = useState("");
    const [programme_list, set_programme_list] = useState([]);
    const [sem_table_list, set_sem_table_list] = useState([]);
    const [student_documents, set_student_documents] = useState("");
    const [errors, set_errors] = useState([]);
    const [school_decision, set_school_decision] = useState("");
    const [application_checklist, set_application_checklist] = useState("");

    // use ref condition
    const studentHighSchoolDiplomaImage = useRef(null);
    const studentTranscriptsHigherEducationalImage = useRef(null);
    const studentResumeImage = useRef(null);
    const studentPassportImage = useRef(null);
    const studentProofEnglishImage = useRef(null);
    const studentEssayExplainingImage = useRef(null);
    const studentCopyofUSVisaImage = useRef(null);
    const studentCopyofCurrentI94Image = useRef(null);
    const studentCopyofCurrentI20Image = useRef(null);
    const studentOfficialTranscriptsSchoolImage = useRef(null);



    // Document

    const [high_school_diploma_and_transcripts, set_high_school_diploma_and_transcripts] = useState("");
    const [transcripts_from_higher_educational, set_transcripts_from_higher_educational] = useState("");
    const [resume, set_resume] = useState("");
    const [photocopy_of_passport_or_equivalent_documents, set_photocopy_of_passport_or_equivalent_documents] = useState("");
    const [proof_of_english, set_proof_of_english] = useState("");
    const [essay_explaining_interest, set_essay_explaining_interest] = useState("");
    const [copy_of_us_visa, set_copy_of_us_visa] = useState("");
    const [copy_of_current_i94, set_copy_of_current_i94] = useState("");
    const [copy_of_current_i20, set_copy_of_current_i20] = useState("");
    const [official_transcripts_school_usa, set_official_transcripts_school_usa] = useState("");
    const [api_high_school_diploma_and_transcripts, set_api_high_school_diploma_and_transcripts] = useState("");
    const [api_transcripts_from_higher_educational, set_api_transcripts_from_higher_educational] = useState("");
    const [api_resume, set_api_resume] = useState("");
    const [api_proof_of_english, set_api_proof_of_english] = useState("");
    const [api_photocopy_of_passport_or_equivalent_documents, set_api_photocopy_of_passport_or_equivalent_documents] = useState("");
    const [api_essay_explaining_interest, set_api_essay_explaining_interest] = useState("");
    const [api_copy_of_us_visa, set_api_copy_of_us_visa] = useState("");
    const [api_copy_of_current_i94, set_api_copy_of_current_i94] = useState("");
    const [api_copy_of_current_i20, set_api_copy_of_current_i20] = useState("");
    const [api_official_transcripts_school_usa, set_api_official_transcripts_school_usa] = useState("");


    const VIEW_DEMOGRAPHIC_INFORMATION = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('student_user_id', USER_DATA.id);
        const VIEW_DEMOGRAPHIC_INFORMATION_API_RESPONSE = await GET_DEMOGRAPHIC_INFORMATION(FORM_DATA);
        if (VIEW_DEMOGRAPHIC_INFORMATION_API_RESPONSE?.data?.status) {
            const StudentDemography = VIEW_DEMOGRAPHIC_INFORMATION_API_RESPONSE?.data?.response?.student;
            const StudentProgrammRegistration = VIEW_DEMOGRAPHIC_INFORMATION_API_RESPONSE?.data?.response?.student;
            const StudentDocuments = VIEW_DEMOGRAPHIC_INFORMATION_API_RESPONSE?.data?.response?.student_documents;
            set_application_checklist(StudentDemography?.application_checklist);
            set_school_decision(StudentDemography?.school_decision);
            set_identify(StudentDemography?.ethnicity);
            set_applicant_status(StudentDemography?.applicant_status);
            set_veteran(StudentDemography?.is_us_military_veteran);
            if (StudentDemography?.applicant_status == 'international student') {
                set_transfer_in_student(StudentDemography?.self_transfer_in_student);
            }
            set_programme_intended((StudentProgrammRegistration?.programme_intended === null) || (StudentProgrammRegistration?.programme_intended === "null") ? "" : StudentProgrammRegistration?.programme_intended);
            set_starting_semester((StudentProgrammRegistration?.starting_semester_id === null) || (StudentProgrammRegistration?.starting_semester_id === "null") ? "" : (StudentProgrammRegistration?.starting_semester_id));
            set_program_format((StudentProgrammRegistration?.program_format === null) || (StudentProgrammRegistration?.program_format === "null") ? "" : StudentProgrammRegistration?.program_format);
            set_starting_cohort(StudentProgrammRegistration?.starting_cohort);

            set_recruiter(VIEW_DEMOGRAPHIC_INFORMATION_API_RESPONSE?.data?.response?.student?.recruiter === null ? "" : VIEW_DEMOGRAPHIC_INFORMATION_API_RESPONSE?.data?.response?.student?.recruiter);
            set_api_high_school_diploma_and_transcripts(StudentDocuments?.high_school_diploma_and_transcripts);
            set_api_transcripts_from_higher_educational(StudentDocuments?.transcripts_from_higher_educational);
            set_api_resume(StudentDocuments?.resume);
            set_api_photocopy_of_passport_or_equivalent_documents(StudentDocuments?.photocopy_of_passport_or_equivalent_documents);
            set_api_proof_of_english(StudentDocuments?.proof_of_english);
            set_api_essay_explaining_interest(StudentDocuments?.essay_explaining_interest);
            set_api_copy_of_us_visa(StudentDocuments?.copy_us_visa);
            set_api_copy_of_current_i94(StudentDocuments?.copy_current_i94);
            set_api_copy_of_current_i20(StudentDocuments?.copy_current_i20);
            set_api_official_transcripts_school_usa(StudentDocuments?.official_transcript_school_in_USA);
            set_loader(false);
        }
        else {
            set_loader(false);
        }
    }



    const PROGRAMS_LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
        const FORM_DATA = new FormData();
        FORM_DATA.append("token", USER_DATA.token);
        const API_RESPONSE = await STUDENT_PROGRAMS_LIST(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            set_programme_list(API_RESPONSE?.data?.programmes);
        } else {
            set_loader(false);
        }
    };


    const ALL_SEMESTER_LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const API_RESPONSE = await ALL_SEMESTERS_LIST(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            set_sem_table_list( API_RESPONSE?.data?.academic_semester);
            set_loader(false);
        } else {
            set_loader(false);
        }
    }



    useEffect(() => {
        PROGRAMS_LIST_API();
        VIEW_DEMOGRAPHIC_INFORMATION();
        ALL_SEMESTER_LIST_API();
    }, []);



    return (
        <>
            {loader ? <SisLoader />
                :
                <>

                    <div>
                        <br />
                        <hr />
                        {application_checklist != "Completed" &&
                        <div className="row">
                            <div className="col-2" style={{ float: "right" }}>
                                <div className="input-box" style={{ float: "right" }}>
                                    <Button
                                        type="primary"
                                        icon={<EditOutlined />}
                                        onClick={() => navigate("/demographic-information")}>
                                        Edit
                                    </Button>
                                </div>
                            </div>
                        </div>
                        }
                        <div className="theme-content-left-head">
                            <h2 style={{ color: "#626262" }}>Demographic Information</h2>
                        </div>
                        <br />
                    </div>
                    <div className="row">
                        <div className="col-3 first-letter-capitalize">
                            <p>How do you identify yourself</p>
                            <h4 style={{ marginTop: "6px" }}>
                                {identify === "american indian or native alaskan" && "American Indian or Native Alaskan"}
                                {identify === "asian or pacific islander" && "Asian or Pacific Islander"}
                                {identify === "african american" && "African American"}
                                {identify === "hispanic" && "Hispanic"}
                                {identify === "white" && "White"}
                                </h4>
                        </div>
                        <div className="col-3 first-letter-capitalize">
                            <p>Applicant Status</p>
                            <h4 style={{ marginTop: "6px" }}>
                                {applicant_status === "u.s. citizen or legal resident" && "U.S. Citizen or Legal Resident"}
                                {applicant_status === "international student" && "International Student"}
                                {applicant_status === "other" && "Other"}
                                </h4>
                        </div>
                        {applicant_status === "u.s. citizen or legal resident" &&
                            <div className="col-3 first-letter-capitalize">
                                <p>Are you a veteran of the U.S. Military?</p>
                                <h4 style={{ marginTop: "6px" }}>
                                    {veteran === 1 && "Yes"}
                                    {veteran === 0 && "No"}
                                    </h4>
                            </div>
                        }
                        {applicant_status === "international student" &&
                            <div className="col-3 first-letter-capitalize">
                                <p>Are you a transfer in student?</p>
                                <h4 style={{ marginTop: "6px" }}>
                                    {transfer_in_student === 1 && "Yes"}
                                    {transfer_in_student === 2 && "No"}
                                    </h4>
                            </div>
                        }
                    </div>
                    <div>
                        <br />
                        <div className="theme-content-left-head">
                            <h3 style={{color:"#626262"}}>Application Documents</h3>
                        </div>
                        <br />
                    </div>
                    <div className="row">
                        <div className="col-6 first-letter-capitalize">
                            <p> Program Degree/Intended</p>
                            <h4 style={{ marginTop: "6px" }}>
                            {programme_list?.length > 0
                                            ? (programme_list?.find(item => item.id === programme_intended)?.title || "--Program Not Found--")
                                            : "--Program Not Selected--"}
                                </h4>
                        </div>
                        {programme_intended != 10  ?
                            <>
                                <div className="col-3 first-letter-capitalize">
                                    <p>Starting Semester</p>
                                    <h4 style={{ marginTop: "6px" }}>
                                        {sem_table_list?.length > 0
                                            ? (sem_table_list?.find(item => item.id === starting_semester)?.title || "--Semester Not Found--")
                                            : "--Semester Not Selected--"}
                                    </h4>
                                </div>
                            </>
                            :
                            <>
                                {programme_intended == 10 &&
                                    <>
                                        <div className="col-3 first-letter-capitalize">
                                            <p>Please choose the program format</p>
                                            <h4 style={{ marginTop: "6px" }}>{program_format}</h4>
                                        </div>
                                        {program_format == "on campus" &&
                                            <>
                                                <div className="col-3 first-letter-capitalize">
                                                    <p>Please select starting semester</p>
                                                    <h4 style={{ marginTop: "6px" }}>
                                                        {sem_table_list?.length > 0
                                                            ? (sem_table_list?.find(item => item.id === starting_semester)?.title || "--Semester Not Found--")
                                                            : "--Semester Not Selected--"}
                                                    </h4>
                                                </div>
                                            </>
                                        }
                                        {program_format == "distance education" &&
                                            <>
                                                <div className="col-3 first-letter-capitalize">
                                                    <p>Please Select Starting Cohort</p>
                                                    <h4 style={{ marginTop: "6px" }}>{starting_cohort}</h4>
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </div>

                    <div>
                        <br />
                        <div className="theme-content-left-head">
                            <h3 style={{color:"#626262"}}>Documents</h3>
                        </div>
                        <br />
                    </div>
                    <div className="row" style={{ marginTop: "15px" }}>
                        <div className="col-3 " style={{ position: "relative" }}>
                            <h4 className="first-letter-capitalize" style={{ textTransform: "none" }}>High School Diploma and Transcripts</h4>
                            <ul className="document-list">
                                <li
                                    onClick={() =>
                                        window.open(
                                            SIS_STORAGE +
                                            "/enrollments/" +
                                            api_high_school_diploma_and_transcripts
                                        )
                                    }  >
                                    {api_high_school_diploma_and_transcripts}
                                </li>
                            </ul>
                        </div>
                        <div className="col-3  " style={{ position: "relative" }}>
                            <h4 className="first-letter-capitalize" style={{ textTransform: "none" }}>Transcription from Higher Education</h4>
                            <ul className="document-list">
                                <li
                                    onClick={() =>
                                        window.open(
                                            SIS_STORAGE +
                                            "/enrollments/" +
                                            api_transcripts_from_higher_educational
                                        )
                                    } >
                                    {api_transcripts_from_higher_educational}
                                </li>
                            </ul>
                        </div>
                        <div className="col-3 " style={{ position: "relative"}}>
                            <h4 className="first-letter-capitalize">Essay Explaining Interest</h4>
                            <ul className="document-list">
                                <li
                                    onClick={() =>
                                        window.open(
                                            SIS_STORAGE +
                                            "/enrollments/" +
                                            api_essay_explaining_interest
                                        )
                                    }  >
                                    {api_essay_explaining_interest}
                                </li>
                            </ul>
                        </div>
                        <div className="col-3 " style={{ position: "relative"}}>
                            <h4 className="first-letter-capitalize">Resume</h4>
                            <ul className="document-list">
                                <li
                                    onClick={() =>
                                        window.open(
                                            SIS_STORAGE +
                                            "/enrollments/" +
                                            api_resume
                                        )
                                    }  >
                                    {api_resume}
                                </li>
                            </ul>
                        </div>
                       
                    </div>

                    <div className="row">
                        {((applicant_status == "other") || (transfer_in_student == "1") || (transfer_in_student == "2")) &&
                            <>
                                {/* Photocopy of passpart or equivaient documents */}
                                <div className="col-3 " style={{ position: "relative", textTransform: 'none' }}>
                                    <h4 className="first-letter-capitalize">Photocopy of Passport or Equivaient Documents</h4>
                                    <ul className="document-list">
                                        <li
                                            onClick={() =>
                                                window.open(
                                                    SIS_STORAGE +
                                                    "/enrollments/" +
                                                    api_photocopy_of_passport_or_equivalent_documents
                                                )
                                            }  >
                                            {api_photocopy_of_passport_or_equivalent_documents}
                                        </li>
                                    </ul>
                                </div>
                                {/* Proof of english */}
                                <div className="col-3 " style={{ position: "relative", textTransform: 'none' }}>
                                    <h4 className="first-letter-capitalize">Proof of English</h4>
                                    <ul className="document-list">
                                        <li
                                            onClick={() =>
                                                window.open(
                                                    SIS_STORAGE +
                                                    "/enrollments/" +
                                                    api_proof_of_english
                                                )
                                            }  >
                                            {api_proof_of_english}
                                        </li>
                                    </ul>
                                </div>

                                {((applicant_status == "other") || (transfer_in_student == "1")) &&

                                    <>
                                        {/* copy of US Visa */}
                                        <div className="col-3 " style={{ position: "relative",textTransform: 'none' }}>
                                            <h4 className="first-letter-capitalize">Copy of US Visa</h4>
                                            <ul className="document-list">
                                                <li
                                                    onClick={() =>
                                                        window.open(
                                                            SIS_STORAGE +
                                                            "/enrollments/" +
                                                            api_copy_of_us_visa
                                                        )
                                                    }  >
                                                    {api_copy_of_us_visa}
                                                </li>
                                            </ul>
                                        </div>

                                        {/* copy of Current I-94 */}
                                        <div className="col-3 " style={{ position: "relative", textTransform: 'none' }}>
                                            <h4 className="first-letter-capitalize">Copy of Current I-94</h4>
                                            <ul className="document-list">
                                                <li
                                                    onClick={() =>
                                                        window.open(
                                                            SIS_STORAGE +
                                                            "/enrollments/" +
                                                            api_copy_of_current_i94
                                                        )
                                                    }  >
                                                    {api_copy_of_current_i94}
                                                </li>
                                            </ul>
                                        </div>
                                    </>
                                }
                            </>
                        }

                    </div>

                    {transfer_in_student == "1" &&
                        <>
                            <div className="row">
                                {/* copy of Current I-20 */}
                                <div className="col-3 " style={{ position: "relative",textTransform: "none" }}>
                                    <h4 className="first-letter-capitalize">Copy of Current I-20</h4>
                                    <ul className="document-list">
                                        <li
                                            onClick={() =>
                                                window.open(
                                                    SIS_STORAGE +
                                                    "/enrollments/" +
                                                    api_copy_of_current_i20
                                                )
                                            }  >
                                            {api_copy_of_current_i20}
                                        </li>
                                    </ul>
                                </div>
                                {/* official transcripts school usa */}
                                <div className="col-3 " style={{ position: "relative",textTransform: "none" }}>
                                    <h4 className="first-letter-capitalize">Official Transcripts From the School in the USA</h4>
                                    <ul className="document-list">
                                        <li
                                            onClick={() =>
                                                window.open(
                                                    SIS_STORAGE +
                                                    "/enrollments/" +
                                                    api_official_transcripts_school_usa
                                                )
                                            }  >
                                            {api_official_transcripts_school_usa}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </>
                    }
                    <br/>
                    <div className="row">
                        {/* Recruiter */}
                        <div className="col-3">
                        <p> Recruiter</p>
                        <h4 style={{ marginTop: "6px" }}>{recruiter}</h4>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default DemographicInformationPreview;