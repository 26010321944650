import { Button, Input, Select, Spin, notification } from "antd";
import { useEffect, useRef, useState } from "react";
import { ALL_SEMESTERS_LIST, DEMOGRAPHIC_INFORMATION_SAVE, GET_DEMOGRAPHIC_INFORMATION, SIS_STORAGE, STUDENT_PROGRAMS_LIST } from "../../../apis/apis";
import { useNavigate } from "react-router-dom";
import { CloseOutlined, CloudUploadOutlined, LoadingOutlined, SmileOutlined } from "@ant-design/icons";
import SisLoader from "../../../widgets/loader";


const DemographicInformation = () => {

    const navigate = useNavigate();
    const [check_doc_save_status, set_check_doc_save_status] = useState("");
    const [loader, set_loader] = useState(true);
    const [identify, set_identify] = useState("");
    const [applicant_status, set_applicant_status] = useState("");
    const [veteran, set_veteran] = useState("");
    const [starting_semester, set_starting_semester] = useState("");
    const [starting_cohort, set_starting_cohort] = useState("");
    const [program_format, set_program_format] = useState("");
    const [transfer_in_student, set_transfer_in_student] = useState("");
    const [recruiter, set_recruiter] = useState(" ");
    const [programme_intended, set_programme_intended] = useState("");
    const [programme_list, set_programme_list] = useState([]);
    const [sem_table_list, set_sem_table_list] = useState([]);
    const [errors, set_errors] = useState([]);
    const [school_decision, set_school_decision] = useState("");
    const [application_checklist, set_application_checklist] = useState("");

    // use ref condition
    const studentHighSchoolDiplomaImage = useRef(null);
    const studentTranscriptsHigherEducationalImage = useRef(null);
    const studentResumeImage = useRef(null);
    const studentPassportImage = useRef(null);
    const studentProofEnglishImage = useRef(null);
    const studentEssayExplainingImage = useRef(null);
    const studentCopyofUSVisaImage = useRef(null);
    const studentCopyofCurrentI94Image = useRef(null);
    const studentCopyofCurrentI20Image = useRef(null);
    const studentOfficialTranscriptsSchoolImage = useRef(null);



    // Document

    const [high_school_diploma_and_transcripts, set_high_school_diploma_and_transcripts] = useState("");
    const [transcripts_from_higher_educational, set_transcripts_from_higher_educational] = useState("");
    const [resume, set_resume] = useState("");
    const [photocopy_of_passport_or_equivalent_documents, set_photocopy_of_passport_or_equivalent_documents] = useState("");
    const [proof_of_english, set_proof_of_english] = useState("");
    const [essay_explaining_interest, set_essay_explaining_interest] = useState("");
    const [copy_of_us_visa, set_copy_of_us_visa] = useState("");
    const [copy_of_current_i94, set_copy_of_current_i94] = useState("");
    const [copy_of_current_i20, set_copy_of_current_i20] = useState("");
    const [official_transcripts_school_usa, set_official_transcripts_school_usa] = useState("");
    const [api_high_school_diploma_and_transcripts, set_api_high_school_diploma_and_transcripts] = useState("");
    const [api_transcripts_from_higher_educational, set_api_transcripts_from_higher_educational] = useState("");
    const [api_resume, set_api_resume] = useState("");
    const [api_proof_of_english, set_api_proof_of_english] = useState("");
    const [api_photocopy_of_passport_or_equivalent_documents, set_api_photocopy_of_passport_or_equivalent_documents] = useState("");
    const [api_essay_explaining_interest, set_api_essay_explaining_interest] = useState("");
    const [api_copy_of_us_visa, set_api_copy_of_us_visa] = useState("");
    const [api_copy_of_current_i94, set_api_copy_of_current_i94] = useState("");
    const [api_copy_of_current_i20, set_api_copy_of_current_i20] = useState("");
    const [api_official_transcripts_school_usa, set_api_official_transcripts_school_usa] = useState("");

    const[payment_status , set_payment_status] = useState("");


    const PROGRAMS_LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
        const FORM_DATA = new FormData();
        FORM_DATA.append("token", USER_DATA.token);
        const API_RESPONSE = await STUDENT_PROGRAMS_LIST(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            set_programme_list(API_RESPONSE?.data?.programmes);
        } else {
            set_loader(false);
        }
    };


    const ALL_SEMESTER_LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const API_RESPONSE = await ALL_SEMESTERS_LIST(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            // set_sem_table_list(API_RESPONSE?.data?.academic_semester);
            // Get the academic semesters from the response
            const allSemesters = API_RESPONSE?.data?.academic_semester;

            // Get the last 3 semesters
            const lastThreeSemesters = allSemesters.slice(-3);

            // Set the state with the last 3 semesters
            set_sem_table_list(lastThreeSemesters);
            set_loader(false);
        } else {
            set_loader(false);
        }
    }

    useEffect(() => {
        const local_payment = JSON.parse(localStorage.getItem("sis_user_data"));
        set_payment_status(local_payment?.payment_status);
        PROGRAMS_LIST_API();
        VIEW_DEMOGRAPHIC_INFORMATION();
        ALL_SEMESTER_LIST_API();
    }, []);

    // handle Image Change

    const handleImageChange = (e, type) => {
        const files = e.target.files;
        if (type === "high_school_diploma_and_transcripts") {
            set_high_school_diploma_and_transcripts(files[0]);
            // set_api_high_school_diploma_and_transcripts(files[0]);
        }
        if (type === "resume") {
            set_resume(files[0]);
            // set_api_resume(files[0]);
        }
        if (type === "photocopy_of_passport_or_equivalent_documents") {
            set_photocopy_of_passport_or_equivalent_documents(files[0]);
            // set_api_photocopy_of_passport_or_equivalent_documents(files[0]);
        }
        if (type === "proof_of_english") {
            set_proof_of_english(files[0]);
            // set_api_proof_of_english(files[0]);
        }
        if (type === "transcripts_from_higher_educational") {
            set_transcripts_from_higher_educational(files[0]);
            // set_api_transcripts_from_higher_educational(files[0]);
        }
        if (type === "essay_explaining_interest") {
            set_essay_explaining_interest(files[0]);
            // set_api_essay_explaining_interest(files[0]);
        }
        if (type === "copy_of_us_visa") {
            set_copy_of_us_visa(files[0]);
        }
        if (type === "copy_of_current_i94") {
            set_copy_of_current_i94(files[0]);
        }
        if (type === "official_transcripts_school_usa") {
            set_official_transcripts_school_usa(files[0]);
        }

        if (type === "copy_of_current_i20") {
            set_copy_of_current_i20(files[0]);
        }

    };

    // handleRemove Image
    const handleRemoveImage = (e, type) => {

        if (type === "official_transcripts_school_usa") {
            set_official_transcripts_school_usa("");
            studentOfficialTranscriptsSchoolImage.current.value = ''
        }

        if (type === "copy_of_current_i20") {
            set_copy_of_current_i20("");
            studentCopyofCurrentI20Image.current.value = ''
        }

        if (type === "copy_of_current_i94") {
            set_copy_of_current_i94("");
            studentCopyofCurrentI94Image.current.value = ''
        }

        if (type === "copy_of_us_visa") {
            set_copy_of_us_visa("");
            studentCopyofUSVisaImage.current.value = ''
        }
        if (type === "high_school_diploma_and_transcripts") {
            set_high_school_diploma_and_transcripts("");
            studentHighSchoolDiplomaImage.current.value = ''
        }
        if (type === "resume") {
            set_resume("");
            studentResumeImage.current.value = '';
        }
        if (type === "photocopy_of_passport_or_equivalent_documents") {
            set_photocopy_of_passport_or_equivalent_documents("");
            studentPassportImage.current.value = '';
        }
        if (type === "transcripts_from_higher_educational") {
            set_transcripts_from_higher_educational("");
            studentTranscriptsHigherEducationalImage.current.value = '';
        }
        if (type === "proof_of_english") {
            set_proof_of_english("");
            studentProofEnglishImage.current.value = '';
        }
        if (type === "essay_explaining_interest") {
            set_essay_explaining_interest("");
            studentEssayExplainingImage.current.value = '';
        }
    };

    const DEMOGRAPHIC_INFORMATION_DRAFT_NEXT = async (doc_save_status) => {
        set_errors("");
        set_loader(true);
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('student_user_id', USER_DATA.id);
        FORM_DATA.append('doc_save_status', doc_save_status);
        FORM_DATA.append('ethnicity', (identify === null) || (identify === undefined) ? "" : identify);
        FORM_DATA.append('applicant_status', (applicant_status === null) || (applicant_status === undefined) ? "" : applicant_status);
        FORM_DATA.append('is_us_military_veteran', (veteran === null) || (veteran === undefined) ? "" : veteran);
        FORM_DATA.append('transfer_in_student', (transfer_in_student === null) || (transfer_in_student === undefined) ? "" : transfer_in_student);

        FORM_DATA.append('programme_intended', (programme_intended === null) || (programme_intended === undefined) ? "" : programme_intended);
        FORM_DATA.append('starting_semester_id', (starting_semester === null) || (starting_semester === null) ? "" : starting_semester);
        FORM_DATA.append('starting_cohort', (starting_cohort === null) || (starting_cohort === undefined) ? "" : starting_cohort);
        FORM_DATA.append('program_format', (program_format === null) || (program_format === undefined) ? "" : program_format);
        FORM_DATA.append('recruiter', (recruiter === null) || (recruiter === undefined) ? "" : recruiter);
        FORM_DATA.append("high_school_diploma_and_transcripts", high_school_diploma_and_transcripts);
        FORM_DATA.append("transcripts_from_higher_educational", transcripts_from_higher_educational);
        FORM_DATA.append("resume", resume);
        FORM_DATA.append("photocopy_of_passport_or_equivalent_documents", photocopy_of_passport_or_equivalent_documents);
        FORM_DATA.append("proof_of_english", proof_of_english);
        FORM_DATA.append("essay_explaining_interest", essay_explaining_interest);
        FORM_DATA.append("copy_us_visa", copy_of_us_visa);
        FORM_DATA.append("copy_current_i94", copy_of_current_i94);
        FORM_DATA.append("copy_current_i20", copy_of_current_i20);
        FORM_DATA.append("official_transcript_school_in_USA", official_transcripts_school_usa);

        const API_RESPONSE = await DEMOGRAPHIC_INFORMATION_SAVE(FORM_DATA);

        if (API_RESPONSE?.data?.status) {
            localStorage.setItem('demographic_information_status',API_RESPONSE?.data?.doc_save_status);
            if (API_RESPONSE?.data?.doc_save_status == "1") {

                notification.open({
                    message: "Success!!",
                    description: "Demographic Information Save as Draft.",
                    icon: <SmileOutlined style={{ color: "green" }} />,
                });
                VIEW_DEMOGRAPHIC_INFORMATION();
                set_loader(false);
            }

            if (API_RESPONSE?.data?.doc_save_status == "2") {
                notification.open({
                    message: "Success!!",
                    description: "Demographic Information Saved Sucessfully",
                    icon: <SmileOutlined style={{ color: "green" }} />,
                });
                if(!payment_status)
                {
                    navigate('/finance-declaration');
                }
            }
            set_loader(false);

        } else {
            set_errors(API_RESPONSE?.data?.errors);
            set_loader(false);
        }
    }



    const VIEW_DEMOGRAPHIC_INFORMATION = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('student_user_id', USER_DATA.id);
        const VIEW_DEMOGRAPHIC_INFORMATION_API_RESPONSE = await GET_DEMOGRAPHIC_INFORMATION(FORM_DATA);
        if (VIEW_DEMOGRAPHIC_INFORMATION_API_RESPONSE?.data?.status) {
            localStorage.setItem('demographic_information_status',VIEW_DEMOGRAPHIC_INFORMATION_API_RESPONSE?.data?.response?.student?.doc_save_status);
            set_check_doc_save_status(VIEW_DEMOGRAPHIC_INFORMATION_API_RESPONSE?.data?.response?.student?.doc_save_status);
            const StudentDemography = VIEW_DEMOGRAPHIC_INFORMATION_API_RESPONSE?.data?.response?.student;
            const StudentProgrammRegistration = VIEW_DEMOGRAPHIC_INFORMATION_API_RESPONSE?.data?.response?.student;
            const StudentDocuments = VIEW_DEMOGRAPHIC_INFORMATION_API_RESPONSE?.data?.response?.student_documents;
            set_application_checklist(StudentDemography?.application_checklist);
            set_school_decision(StudentDemography?.school_decision);
            set_identify(StudentDemography?.ethnicity);
            set_applicant_status(StudentDemography?.applicant_status);
            set_veteran(StudentDemography?.is_us_military_veteran);
            if (StudentDemography?.applicant_status == 'international student') {
                set_transfer_in_student(StudentDemography?.self_transfer_in_student === null ? "" : StudentDemography?.self_transfer_in_student);
            }
            set_programme_intended((StudentProgrammRegistration?.programme_intended === null) || (StudentProgrammRegistration?.programme_intended === "null") ? "" : StudentProgrammRegistration?.programme_intended);
            set_starting_semester((StudentProgrammRegistration?.starting_semester_id === null) || (StudentProgrammRegistration?.starting_semester_id === "null") ? "" : (StudentProgrammRegistration?.starting_semester_id));
            set_program_format((StudentProgrammRegistration?.program_format === null) || (StudentProgrammRegistration?.program_format === "null") ? "" : StudentProgrammRegistration?.program_format);
            set_starting_cohort(StudentProgrammRegistration?.starting_cohort);

            set_recruiter(VIEW_DEMOGRAPHIC_INFORMATION_API_RESPONSE?.data?.response?.student?.recruiter === null ? "" : VIEW_DEMOGRAPHIC_INFORMATION_API_RESPONSE?.data?.response?.student?.recruiter);
            set_api_high_school_diploma_and_transcripts(StudentDocuments?.high_school_diploma_and_transcripts);
            set_api_transcripts_from_higher_educational(StudentDocuments?.transcripts_from_higher_educational);
            set_api_resume(StudentDocuments?.resume);
            set_api_photocopy_of_passport_or_equivalent_documents(StudentDocuments?.photocopy_of_passport_or_equivalent_documents);
            set_api_proof_of_english(StudentDocuments?.proof_of_english);
            set_api_essay_explaining_interest(StudentDocuments?.essay_explaining_interest);
            set_api_copy_of_us_visa(StudentDocuments?.copy_us_visa);
            set_api_copy_of_current_i94(StudentDocuments?.copy_current_i94);
            set_api_copy_of_current_i20(StudentDocuments?.copy_current_i20);
            set_api_official_transcripts_school_usa(StudentDocuments?.official_transcript_school_in_USA);
            set_loader(false);
        }
        else {
            set_loader(false);
        }
    }




    return (<>
        {loader ?
            <>
                <SisLoader />
            </>
            :
            <>
                {!payment_status && <>
                    <div className='row'>
                    <div className='online-form-step-box'>
                        <div className={'online-form-step'} onClick={() => 
                                navigate('/')
                        }>
                            <div className='online-form-step-line'></div>
                            <span>1</span>
                            <h3>Basic Information </h3>
                        </div>
                        <div className={'online-form-step running'}>
                            <div className='online-form-step-line'></div>
                            <span>2</span>
                            <h3>Demographic Information</h3>
                        </div>
                        <div className={'online-form-step'} onClick={() =>{
                            if (check_doc_save_status == "2") navigate('/finance-declaration')}}>
                            <div className='online-form-step-line'></div>
                            <span>3</span>
                            <h3>Finance Declaration</h3>
                        </div>
                        <div className={'online-form-step'} onClick={() =>{
                            if (check_doc_save_status == "2") navigate('/agreement')}}>
                            <div className='online-form-step-line'></div>
                            <span>4</span>
                            <h3>Agreement</h3>
                        </div>
                        <div className={'online-form-step'} onClick={() =>{
                            if (check_doc_save_status == "2") navigate('/formpreview')}}>
                            <div className='online-form-step-line'></div>
                            <span>5</span>
                            <h3>Preview</h3>
                        </div>
                        <div className={'online-form-step'} onClick={() =>{
                            if (check_doc_save_status == "2") navigate('/payment')}}>
                            <span>6</span>
                            <h3>Payment</h3>
                        </div>
                    </div>
                </div>
                </>}
                <div>
                    {!payment_status && 
                    <>
                     <br />
                     <hr />
                     <br />
                     </>
                    }
                    <div className="theme-content-left-head">
                        <h3>Demographic Information </h3>
                    </div>
                    <br />
                </div>
                <div className="row">
                    <p style={{ paddingLeft: "10px" }}>The US federal government requires universities to report students’ demographic data. Lincoln University confirms that the information will not be used in a discriminatory manner. Please provide the following:</p>
                    <div className="col-3">
                        <div className="input-box">
                            <label>
                                How do you identify yourself
                            </label>
                            <Select
                             disabled={localStorage.getItem("payment_status") == 1}
                                value={identify}
                                style={{ width: "100%", height: "40px" }}
                                onChange={(value) => set_identify(value)}
                                placeholder="--identify yourself--"
                            >
                                <Select.Option value="">-Please select one option-</Select.Option>
                                <Select.Option value="american indian or native alaskan">American Indian or Native Alaskan</Select.Option>
                                <Select.Option value="asian or pacific islander">Asian or Pacific Islander</Select.Option>
                                <Select.Option value="african american">African American</Select.Option>
                                <Select.Option value="hispanic">Hispanic</Select.Option>
                                <Select.Option value="white">White</Select.Option>
                            </Select>
                            {errors?.identify && (
                                <>
                                    <span style={{ color: "red" }}>{errors?.identify[0]}</span>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="input-box">
                            <label>
                                Applicant Status<i style={{ color: "red" }}>*</i>
                            </label>
                            <Select
                             disabled={localStorage.getItem("payment_status") == 1}
                                value={applicant_status}
                                style={{ width: "100%", height: "40px" }}
                                onChange={(value) => { set_applicant_status(value); set_veteran(""); set_transfer_in_student("") }}
                                placeholder="--select applicant status--"
                            >
                                <Select.Option value="">-Please select one option-</Select.Option>
                                <Select.Option value="u.s. citizen or legal resident">U.S. Citizen or Legal Resident</Select.Option>
                                <Select.Option value="international student">International Student</Select.Option>
                                <Select.Option value="other">Other</Select.Option>
                            </Select>
                            {errors?.applicant_status && (
                                <>
                                    <span style={{ color: "red" }}>{errors?.applicant_status[0]}</span>
                                </>
                            )}
                        </div>
                    </div>
                    {applicant_status === "u.s. citizen or legal resident" &&
                        <div className="col-3">
                            <div className="input-box">
                                <label>
                                    Are you a veteran of the U.S. Military?
                                </label>
                                <Select
                                 disabled={localStorage.getItem("payment_status") == 1}
                                    value={veteran}
                                    style={{ width: "100%", height: "40px" }}
                                    onChange={(value) => set_veteran(value)}
                                    placeholder="--veteran status--"
                                >
                                    <Select.Option value="">-Please select one option-</Select.Option>
                                    <Select.Option value={1}>Yes</Select.Option>
                                    <Select.Option value={0}>No</Select.Option>
                                </Select>
                                {errors?.veteran && (
                                    <>
                                        <span style={{ color: "red" }}>{errors?.veteran[0]}</span>
                                    </>
                                )}
                            </div>
                        </div>
                    }
                    {applicant_status === "international student" &&
                        <div className="col-3">
                            <div className="input-box">
                                <label>
                                    Are you a transfer in student ?<i style={{ color: "red" }}>*</i>
                                </label>
                                <Select
                                 disabled={localStorage.getItem("payment_status") == 1}
                                    value={transfer_in_student}
                                    style={{ width: "100%", height: "40px" }}
                                    onChange={(value) => set_transfer_in_student(value)}
                                    placeholder="--transfer student status--"
                                >
                                    <Select.Option value="">-Please select one option-</Select.Option>
                                    <Select.Option value={1}>Yes</Select.Option>
                                    <Select.Option value={2}>No</Select.Option>
                                </Select>
                                {errors?.transfer_in_student && (
                                    <>
                                        <span style={{ color: "red" }}>{errors?.transfer_in_student[0]}</span>
                                    </>
                                )}
                            </div>
                        </div>
                    }
                </div>

                <div>
                    <br />
                    <hr />
                    <br />
                    <div className="theme-content-left-head">
                        <h3>Application Documents</h3>
                    </div>
                    <br />
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="input-box">
                            <label>
                                Program Degree/Intended<i style={{ color: "red" }}>*</i>
                            </label>
                            <Select
                             disabled={localStorage.getItem("payment_status") == 1}
                                placeholder="--Select programme--"
                                value={programme_intended}
                                style={{ width: "100%" }}
                                onChange={(value) =>{ set_programme_intended(value);
                                    set_starting_semester("");
                                    set_program_format("");
                                    set_starting_cohort("");
                                }}
                            >
                                <Select.Option value="">Please select a program</Select.Option>
                                {programme_list?.map((item) => {
                                    if (item?.status === 1 && item?.soft_delete === 0) {
                                        return (
                                            <Select.Option key={item?.id} value={item?.id}>
                                                {item?.title}
                                            </Select.Option>
                                        );
                                    }
                                })}
                            </Select>
                            <br/><br/>
                            {errors?.programme_intended && (
                                <>
                                    <span style={{ color: "red" }}>
                                        {errors?.programme_intended[0]}
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                    {programme_intended != 10 && programme_intended != "" ?
                        <>
                            <div className="col-3">
                                <div className="input-box">
                                    <label>
                                        Please select starting semester
                                    </label>
                                    <Select
                                     disabled={localStorage.getItem("payment_status") == 1}
                                     value={starting_semester}
                                      onChange={(value) => set_starting_semester(value)}
                                       style={{ width: "100%" }} placeholder="--Select Semester--">
                                        <Select.Option value="">Please select starting semester</Select.Option>
                                        {sem_table_list?.length > 0 &&
                                            sem_table_list?.map(itemv => (
                                                <Select.Option
                                                    key={itemv.id}
                                                    value={itemv.id}>{itemv.title}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                    {errors?.starting_semester && (
                                        <>
                                            <span style={{ color: "red" }}>{errors?.starting_semester[0]}</span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {programme_intended == 10 &&
                                <>
                                    <div className="col-3">
                                        <div className="input-box">
                                            <label>
                                                Please choose the program format<i style={{ color: "red" }}>*</i>
                                            </label>
                                            <Select
                                            disabled={localStorage.getItem("payment_status") == 1}
                                                value={program_format}
                                                style={{ width: "100%", height: "40px" }}
                                                onChange={(value) => { set_program_format(value); set_starting_semester(""); set_starting_cohort("") }}
                                                placeholder="--select program format--"
                                            >
                                                <Select.Option value="">-Please select program format-</Select.Option>
                                                <Select.Option value="on campus">On-Campus</Select.Option>
                                                <Select.Option value="distance education">Distance Education</Select.Option>
                                            </Select>
                                            {errors?.program_format && (
                                                <>
                                                    <span style={{ color: "red" }}>{errors?.program_format[0]}</span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {program_format == "on campus" &&
                                        <>
                                            <div className="col-3">
                                                <div className="input-box">
                                                    <label>
                                                        Please select starting semester
                                                    </label>
                                                    <Select 
                                                    disabled={localStorage.getItem("payment_status") == 1}
                                                    value={starting_semester} 
                                                    onChange={(value) => set_starting_semester(value)} 
                                                    style={{ width: "100%" }} 
                                                    placeholder="--Select Semester--">
                                                         <Select.Option value="">Please select starting semester</Select.Option>
                                                        {sem_table_list?.length > 0 && <>
                                                            {sem_table_list?.map(itemv => (
                                                                <><Select.Option value={itemv.id}>{itemv.title}</Select.Option></>
                                                            ))}
                                                        </>}
                                                    </Select>
                                                    {errors?.starting_semester && (
                                                        <>
                                                            <span style={{ color: "red" }}>{errors?.starting_semester[0]}</span>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {program_format == "distance education" &&
                                        <>
                                            <div className="col-3">
                                                <div className="input-box">
                                                    <label>
                                                        Please Select Starting Cohort
                                                    </label>
                                                    <Select
                                                     disabled={localStorage.getItem("payment_status") == 1}
                                                        value={starting_cohort}
                                                        style={{ width: "100%", height: "40px" }}
                                                        onChange={(value) => set_starting_cohort(value)}
                                                        placeholder="--select starting cohort--"
                                                    >
                                                        <Select.Option value="">Please select starting cohort</Select.Option>
                                                        <Select.Option value="1">Cohort 1 - Finance Management and Investment</Select.Option>
                                                        <Select.Option value="2">Cohort 2 - International Business</Select.Option>
                                                        <Select.Option value="3">Cohort 3 - General Business</Select.Option>
                                                    </Select>
                                                    {errors?.starting_cohort && (
                                                        <>
                                                            <span style={{ color: "red" }}>{errors?.starting_cohort[0]}</span>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </>
                    }
                </div>

                <div>
                    <br />
                    <hr />
                    <br />
                    <div className="theme-content-left-head">
                        <h3>Documents</h3>
                    </div>
                    <br />
                </div>


                <div className="row">
                    <div className="col-3">
                        <div className="input-box" style={{ position: "relative" }}>
                            <label style={{ textTransform: "none" }}>
                                High School Diploma and Transcripts
                            </label>
                            <label className="upload-box upload-box-2">
                                <input
                                  disabled={application_checklist=="Completed"}
                                    ref={studentHighSchoolDiplomaImage}
                                    type="file"
                                    id="upload-high-school-images"
                                    style={{ height: "0px", overflow: "hidden" }}
                                    onChange={(e) =>
                                        handleImageChange(
                                            e,
                                            "high_school_diploma_and_transcripts"
                                        )
                                    }
                                />
                                <CloudUploadOutlined />
                                Upload

                            </label>
                            {high_school_diploma_and_transcripts ? (
                                <>
                                    <div className="image-box" style={{ width: "145px", height: "60px", padding: "11px", margin: "9px" }}>
                                        <Button onClick={() => window.open(URL.createObjectURL(high_school_diploma_and_transcripts))}>Preview</Button>
                                        <button className="image-remove" style={{ top: "5px", bottom: "auto", left: "auto", right: "15px" }} onClick={(e) => handleRemoveImage(e, "high_school_diploma_and_transcripts")}
                                        >
                                            <CloseOutlined />
                                        </button>
                                    </div>

                                </>
                            ) : (
                                <>
                                    {api_high_school_diploma_and_transcripts && (
                                        <>
                                            <div className="image-box" style={{ width: "105px", height: "60px", padding: "11px", margin: "9px" }}>
                                                <Button onClick={() => window.open(SIS_STORAGE + "/enrollments/" + api_high_school_diploma_and_transcripts)}>Preview</Button>

                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            {errors?.high_school_diploma_and_transcripts && (
                                <>
                                    <span style={{ color: "red" }}>
                                        {errors?.high_school_diploma_and_transcripts[0]}
                                    </span>
                                </>
                            )}
                        </div>
                    </div>

                    {/* Transcripts from higher educational */}
                    <div className="col-3">
                        <div className="input-box" style={{ position: "relative" }}>
                            <label>
                                Transcripts From Higher Educational
                            </label>
                            <label className="upload-box upload-box-2">
                                {" "}
                                <input
                                  disabled={application_checklist=="Completed"}
                                    ref={studentTranscriptsHigherEducationalImage}
                                    type="file"
                                    id="upload-higher-education-images"
                                    style={{ height: "0px", overflow: "hidden" }}
                                    onChange={(e) =>
                                        handleImageChange(
                                            e,
                                            "transcripts_from_higher_educational"
                                        )
                                    }
                                />{" "}
                                <CloudUploadOutlined />
                                Upload

                            </label>
                            {transcripts_from_higher_educational ? (
                                <>
                                    <div className="image-box" style={{ width: "145px", height: "60px", padding: "11px", margin: "9px" }}>
                                        <Button onClick={() => window.open(URL.createObjectURL(transcripts_from_higher_educational))}>Preview</Button>
                                        <button className="image-remove" style={{ top: "5px", bottom: "auto", left: "auto", right: "15px" }} onClick={(e) => handleRemoveImage(e, "transcripts_from_higher_educational")}
                                        >
                                            <CloseOutlined />
                                        </button>
                                    </div>

                                </>
                            ) : (
                                <>
                                    {api_transcripts_from_higher_educational && (
                                        <>
                                            <div className="image-box" style={{ width: "105px", height: "60px", padding: "11px", margin: "9px" }}>
                                                <Button onClick={() => window.open(SIS_STORAGE + "/enrollments/" + api_transcripts_from_higher_educational)}>Preview</Button>

                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            {errors?.transcripts_from_higher_educational && (
                                <>
                                    <span style={{ color: "red" }}>
                                        {errors?.transcripts_from_higher_educational[0]}
                                    </span>
                                </>
                            )}
                        </div>
                    </div>

                    {/* Resume */}
                    <div className="col-3">
                        <div className="input-box" style={{ position: "relative" }}>
                            <label>
                                Resume
                            </label>
                            <label className="upload-box upload-box-2">
                                {" "}
                                <input
                                  disabled={application_checklist=="Completed"}
                                    ref={studentResumeImage}
                                    type="file"
                                    id="upload-resume-images"
                                    style={{ height: "0px", overflow: "hidden" }}
                                    onChange={(e) => handleImageChange(e, "resume")}
                                />{" "}
                                <CloudUploadOutlined />
                                Upload

                            </label>
                            {resume ? (
                                <>
                                    <div className="image-box" style={{ width: "145px", height: "60px", padding: "11px", margin: "9px" }}>
                                        <Button onClick={() => window.open(URL.createObjectURL(resume))}>Preview</Button>
                                        <button className="image-remove" style={{ top: "5px", bottom: "auto", left: "auto", right: "15px" }} onClick={(e) => handleRemoveImage(e, "resume")}
                                        >
                                            <CloseOutlined />
                                        </button>
                                    </div>

                                </>
                            ) : (
                                <>
                                    {api_resume && (
                                        <>
                                            <div className="image-box" style={{ width: "105px", height: "60px", padding: "11px", margin: "9px" }}>
                                                <Button onClick={() => window.open(SIS_STORAGE + "/enrollments/" + api_resume)}>Preview</Button>

                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            {errors?.resume && (
                                <>
                                    <span style={{ color: "red" }}>
                                        {errors?.resume[0]}
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                    {/* Essay Explaining interest */}
                    <div className="col-3">
                        <div className="input-box" style={{ position: "relative" }}>
                            <label>
                                Essay Explaining interest
                            </label>
                            <label className="upload-box upload-box-2">
                                {" "}
                                <input
                                  disabled={application_checklist=="Completed"}
                                    ref={studentEssayExplainingImage}
                                    type="file"
                                    id="upload-essay-images"
                                    style={{ height: "0px", overflow: "hidden" }}
                                    onChange={(e) =>
                                        handleImageChange(e, "essay_explaining_interest")
                                    }
                                />{" "}
                                <CloudUploadOutlined />
                                Upload

                            </label>
                            {essay_explaining_interest ? (
                                <>
                                    <div className="image-box" style={{ width: "145px", height: "60px", padding: "11px", margin: "9px" }}>
                                        <Button onClick={() => window.open(URL.createObjectURL(essay_explaining_interest))}>Preview</Button>
                                        <button className="image-remove" style={{ top: "5px", bottom: "auto", left: "auto", right: "15px" }} onClick={(e) => handleRemoveImage(e, "essay_explaining_interest")}
                                        >
                                            <CloseOutlined />
                                        </button>
                                    </div>

                                </>
                            ) : (
                                <>
                                    {api_essay_explaining_interest && (
                                        <>
                                            <div className="image-box" style={{ width: "105px", height: "60px", padding: "11px", margin: "9px" }}>
                                                <Button onClick={() => window.open(SIS_STORAGE + "/enrollments/" + api_essay_explaining_interest)}>Preview</Button>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            {errors?.essay_explaining_interest && (
                                <>
                                    <span style={{ color: "red" }}>
                                        {errors?.essay_explaining_interest[0]}
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    {((applicant_status == "other") || (transfer_in_student == "1") || (transfer_in_student == "2")) &&
                        <>
                            {/* Photocopy of passpart or equivaient documents */}
                            <div className="col-3">
                                <div className="input-box" style={{ position: "relative" }}>
                                    <label style={{ textTransform: "none" }}>
                                        Photocopy of Passport or Equivaient Documents{" "}
                                    </label>
                                    <label className="upload-box upload-box-2">
                                        {" "}
                                        <input
                                          disabled={application_checklist=="Completed"}
                                            ref={studentPassportImage}
                                            type="file"
                                            id="upload-passport-images"

                                            style={{ height: "0px", overflow: "hidden" }}
                                            onChange={(e) =>
                                                handleImageChange(
                                                    e,
                                                    "photocopy_of_passport_or_equivalent_documents"
                                                )
                                            }
                                        />{" "}
                                        <CloudUploadOutlined />
                                        Upload
                                    </label>
                                    {photocopy_of_passport_or_equivalent_documents ? (
                                        <>
                                            <div className="image-box" style={{ width: "145px", height: "60px", padding: "11px", margin: "9px" }}>
                                                <Button onClick={() => window.open(URL.createObjectURL(photocopy_of_passport_or_equivalent_documents))}>Preview</Button>
                                                <button className="image-remove" style={{ top: "5px", bottom: "auto", left: "auto", right: "15px" }} onClick={(e) => handleRemoveImage(e, "photocopy_of_passport_or_equivalent_documents")}
                                                >
                                                    <CloseOutlined />
                                                </button>
                                            </div>

                                        </>
                                    ) : (
                                        <>
                                            {api_photocopy_of_passport_or_equivalent_documents && (
                                                <>
                                                    <div className="image-box" style={{ width: "105px", height: "60px", padding: "11px", margin: "9px" }}>
                                                        <Button onClick={() => window.open(SIS_STORAGE + "/enrollments/" + api_photocopy_of_passport_or_equivalent_documents)}>Preview</Button>

                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                    {errors?.photocopy_of_passport_or_equivalent_documents && (
                                        <>
                                            <span style={{ color: "red" }}>
                                                {
                                                    errors
                                                        ?.photocopy_of_passport_or_equivalent_documents[0]
                                                }
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>

                            {/* Proof of english */}
                            <div className="col-3">
                                <div className="input-box" style={{ position: "relative" }}>
                                    <label style={{ textTransform: "none" }}>
                                        Proof of English
                                    </label>
                                    <label className="upload-box upload-box-2">
                                        {" "}
                                        <input
                                          disabled={application_checklist=="Completed"}
                                            ref={studentProofEnglishImage}
                                            type="file"
                                            id="upload-english-images"
                                            style={{ height: "0px", overflow: "hidden" }}
                                            onChange={(e) =>
                                                handleImageChange(e, "proof_of_english")
                                            }
                                        />{" "}
                                        <CloudUploadOutlined />
                                        Upload

                                    </label>
                                    {proof_of_english ? (
                                        <>
                                            <div className="image-box" style={{ width: "145px", height: "60px", padding: "11px", margin: "9px" }}>
                                                <Button onClick={() => window.open(URL.createObjectURL(proof_of_english))}>Preview</Button>
                                                <button className="image-remove" style={{ top: "5px", bottom: "auto", left: "auto", right: "15px" }} onClick={(e) => handleRemoveImage(e, "proof_of_english")}
                                                >
                                                    <CloseOutlined />
                                                </button>
                                            </div>

                                        </>
                                    ) : (
                                        <>
                                            {api_proof_of_english && (
                                                <>
                                                    <div className="image-box" style={{ width: "105px", height: "60px", padding: "11px", margin: "9px" }}>
                                                        <Button onClick={() => window.open(SIS_STORAGE + "/enrollments/" + api_proof_of_english)}>Preview</Button>

                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}

                                    {errors?.proof_of_english && (
                                        <>
                                            <span style={{ color: "red" }}>
                                                {errors?.proof_of_english[0]}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>

                            {((applicant_status == "other") || (transfer_in_student == "1")) &&

                                <>
                                    {/* copy of US Visa */}
                                    <div className="col-3">
                                        <div className="input-box" style={{ position: "relative" }}>
                                            <label style={{ textTransform: "none" }}>
                                                Copy of US Visa
                                            </label>
                                            <label className="upload-box upload-box-2">
                                                {" "}
                                                <input
                                                  disabled={application_checklist=="Completed"}
                                                    ref={studentCopyofUSVisaImage}
                                                    type="file"
                                                    id="upload-visa-images"
                                                    style={{ height: "0px", overflow: "hidden" }}
                                                    onChange={(e) =>
                                                        handleImageChange(e, "copy_of_us_visa")
                                                    }
                                                />{" "}
                                                <CloudUploadOutlined />
                                                Upload

                                            </label>
                                            {copy_of_us_visa ? (
                                                <>
                                                    <div className="image-box" style={{ width: "145px", height: "60px", padding: "11px", margin: "9px" }}>
                                                        <Button onClick={() => window.open(URL.createObjectURL(copy_of_us_visa))}>Preview</Button>
                                                        <button className="image-remove" style={{ top: "5px", bottom: "auto", left: "auto", right: "15px" }} onClick={(e) => handleRemoveImage(e, "copy_of_us_visa")}
                                                        >
                                                            <CloseOutlined />
                                                        </button>
                                                    </div>

                                                </>
                                            ) : (
                                                <>
                                                    {api_copy_of_us_visa && (
                                                        <>
                                                            <div className="image-box" style={{ width: "105px", height: "60px", padding: "11px", margin: "9px" }}>
                                                                <Button onClick={() => window.open(SIS_STORAGE + "/enrollments/" + api_copy_of_us_visa)}>Preview</Button>

                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}

                                            {errors?.copy_of_us_visa && (
                                                <>
                                                    <span style={{ color: "red" }}>
                                                        {errors?.copy_of_us_visa[0]}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    {/* copy of Current I-94 */}
                                    <div className="col-3">
                                        <div className="input-box" style={{ position: "relative" }}>
                                            <label style={{ textTransform: "none" }}>
                                                Copy of Current I-94
                                            </label>
                                            <label className="upload-box upload-box-2">
                                                {" "}
                                                <input
                                                  disabled={application_checklist=="Completed"}
                                                    ref={studentCopyofCurrentI94Image}
                                                    type="file"
                                                    id="upload-i94-images"
                                                    style={{ height: "0px", overflow: "hidden" }}
                                                    onChange={(e) =>
                                                        handleImageChange(e, "copy_of_current_i94")
                                                    }
                                                />{" "}
                                                <CloudUploadOutlined />
                                                Upload

                                            </label>
                                            {copy_of_current_i94 ? (
                                                <>
                                                    <div className="image-box" style={{ width: "145px", height: "60px", padding: "11px", margin: "9px" }}>
                                                        <Button onClick={() => window.open(URL.createObjectURL(copy_of_current_i94))}>Preview</Button>
                                                        <button className="image-remove" style={{ top: "5px", bottom: "auto", left: "auto", right: "15px" }} onClick={(e) => handleRemoveImage(e, "copy_of_current_i94")}
                                                        >
                                                            <CloseOutlined />
                                                        </button>
                                                    </div>

                                                </>
                                            ) : (
                                                <>
                                                    {api_copy_of_current_i94 && (
                                                        <>
                                                            <div className="image-box" style={{ width: "105px", height: "60px", padding: "11px", margin: "9px" }}>
                                                                <Button onClick={() => window.open(SIS_STORAGE + "/enrollments/" + api_copy_of_current_i94)}>Preview</Button>

                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}

                                            {errors?.copy_of_current_i94 && (
                                                <>
                                                    <span style={{ color: "red" }}>
                                                        {errors?.copy_of_current_i94[0]}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }

                </div>
                {transfer_in_student == "1" &&
                    <>
                        <div className="row">
                            {/* copy of Current I-20 */}
                            <div className="col-3">
                                <div className="input-box" style={{ position: "relative" }}>
                                    <label style={{ textTransform: "none" }}>
                                        Copy of Current I-20
                                    </label>
                                    <label className="upload-box upload-box-2">
                                        {" "}
                                        <input
                                          disabled={application_checklist=="Completed"}
                                            ref={studentCopyofCurrentI20Image}
                                            type="file"
                                            id="upload-i20-images"
                                            style={{ height: "0px", overflow: "hidden" }}
                                            onChange={(e) =>
                                                handleImageChange(e, "copy_of_current_i20")
                                            }
                                        />{" "}
                                        <CloudUploadOutlined />
                                        Upload

                                    </label>
                                    {copy_of_current_i20 ? (
                                        <>
                                            <div className="image-box" style={{ width: "145px", height: "60px", padding: "11px", margin: "9px" }}>
                                                <Button onClick={() => window.open(URL.createObjectURL(copy_of_current_i20))}>Preview</Button>
                                                <button className="image-remove" style={{ top: "5px", bottom: "auto", left: "auto", right: "15px" }} onClick={(e) => handleRemoveImage(e, "copy_of_current_i20")}
                                                >
                                                    <CloseOutlined />
                                                </button>
                                            </div>

                                        </>
                                    ) : (
                                        <>
                                            {api_copy_of_current_i20 && (
                                                <>
                                                    <div className="image-box" style={{ width: "105px", height: "60px", padding: "11px", margin: "9px" }}>
                                                        <Button onClick={() => window.open(SIS_STORAGE + "/enrollments/" + api_copy_of_current_i20)}>Preview</Button>

                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}

                                    {errors?.copy_of_current_i20 && (
                                        <>
                                            <span style={{ color: "red" }}>
                                                {errors?.copy_of_current_i20[0]}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                            {/* official transcripts school usa */}
                            <div className="col-3">
                                <div className="input-box" style={{ position: "relative" }}>
                                    <label style={{ textTransform: "none" }}>
                                        Official Transcripts From the School in the USA
                                    </label>
                                    <label className="upload-box upload-box-2">
                                        {" "}
                                        <input
                                        disabled={application_checklist=="Completed"}
                                            ref={studentOfficialTranscriptsSchoolImage}
                                            type="file"
                                            id="upload-official-transcripts-school-images"
                                            style={{ height: "0px", overflow: "hidden" }}
                                            onChange={(e) =>
                                                handleImageChange(e, "official_transcripts_school_usa")
                                            }
                                        />{" "}
                                        <CloudUploadOutlined />
                                        Upload

                                    </label>
                                    {official_transcripts_school_usa ? (
                                        <>
                                            <div className="image-box" style={{ width: "145px", height: "60px", padding: "11px", margin: "9px" }}>
                                                <Button onClick={() => window.open(URL.createObjectURL(official_transcripts_school_usa))}>Preview</Button>
                                                <button className="image-remove" style={{ top: "5px", bottom: "auto", left: "auto", right: "15px" }} onClick={(e) => handleRemoveImage(e, "official_transcripts_school_usa")}
                                                >
                                                    <CloseOutlined />
                                                </button>
                                            </div>

                                        </>
                                    ) : (
                                        <>
                                            {api_official_transcripts_school_usa && (
                                                <>
                                                    <div className="image-box" style={{ width: "105px", height: "60px", padding: "11px", margin: "9px" }}>
                                                        <Button onClick={() => window.open(SIS_STORAGE + "/enrollments/" + api_official_transcripts_school_usa)}>Preview</Button>

                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}

                                    {errors?.official_transcripts_school_usa && (
                                        <>
                                            <span style={{ color: "red" }}>
                                                {errors?.official_transcripts_school_usa[0]}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                }
                <div className="row">
                    {/* Recruiter */}
                    <div className="col-3">
                        <div className="input-box">
                            <label>
                                Recruiter
                            </label>
                            <Input
                             disabled={localStorage.getItem("payment_status") == 1}
                                value={recruiter}
                                placeholder="Recruiter"
                                onChange={(e) =>
                                    set_recruiter(e.target.value)
                                }
                            />
                            {errors?.recruiter && (
                                <>
                                    <span style={{ color: "red" }}>
                                        {errors?.recruiter[0]}
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {/* button */}
                <div className="row"  style={{ display: "flex", margin: 0, gap: "20px" }}>
                {localStorage.getItem("payment_status") == 0 ?
                <>
                        <div className="input-box">
                            {loader ? (
                                <>
                                    <Button type="primary">
                                        <Spin
                                            indicator={
                                                <LoadingOutlined
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "#fff",
                                                        marginRight: "5px",
                                                    }}
                                                />
                                            }
                                        />{" "}
                                        Previous
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                     onClick={() => navigate("/")}
                                      type="primary">
                                        Previous
                                    </Button>
                                </>
                            )}
                        </div>
                        <div className="input-box">
                            {loader ? (
                                <>
                                    <Button type="primary" style={{ width: "100%" }}>
                                        <Spin
                                            indicator={
                                                <LoadingOutlined
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "#fff",
                                                        marginRight: "5px",
                                                    }}
                                                />
                                            }
                                        />{" "}
                                        Next
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button onClick={() => { DEMOGRAPHIC_INFORMATION_DRAFT_NEXT(2) }} type="primary" style={{ width: "100%" }}>
                                        Next
                                    </Button>
                                </>
                            )}
                        </div>  
                        <div className="input-box">
                            {loader ? (
                                <>
                                    <Button type="primary" ghost>
                                        <Spin
                                            indicator={
                                                <LoadingOutlined
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "#fff",
                                                        marginRight: "5px",
                                                    }}
                                                />
                                            }
                                        />{" "}
                                        Save as Draft
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button onClick={() => (DEMOGRAPHIC_INFORMATION_DRAFT_NEXT(1))} type="primary" ghost>
                                        Save as Draft
                                    </Button>
                                </>
                            )}
                        </div>
                    </>
                    :
                    <>
                        <div className="input-box">
                            {loader ? (
                                <>
                                    <Button type="primary" style={{ width: "100%" }}>
                                        <Spin
                                            indicator={
                                                <LoadingOutlined
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "#fff",
                                                        marginRight: "5px",
                                                    }}
                                                />
                                            }
                                        />{" "}
                                        saving
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button onClick={() => { DEMOGRAPHIC_INFORMATION_DRAFT_NEXT(2) }} type="primary" style={{ width: "100%" }}>
                                        Save
                                    </Button>
                                </>
                            )}
                        </div>
                    </>
                    }
                </div>

            </>
        }
    </>
    );
}

export default DemographicInformation;