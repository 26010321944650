import React, { useState } from 'react';
import { Button, Checkbox, Input, Spin } from 'antd';
import './login.css';
import { EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined } from '@ant-design/icons';
import { LOGIN_API } from '../apis/apis';
import { useNavigate } from 'react-router-dom';
const Login = () => {
    const navigate = useNavigate();
    const [email, set_email] = useState('');
    const [password, set_password] = useState('');
    const [remember, set_remember] = useState(false);
    const [loader, set_loader] = useState(false);
    const [errors, set_errors] = useState([]);
    const toggleChecked = () => {
        set_remember(!remember);
    };
    const login = async() => {
          set_loader(true);
         const FORM_DATA = new FormData();
         FORM_DATA.append('email', email);
         FORM_DATA.append('password', password);
         const LOGIN_API_RESPONSE = await LOGIN_API(FORM_DATA);
         console.log('API Response:', LOGIN_API_RESPONSE);
         if(LOGIN_API_RESPONSE.data.status){
              const apiData = LOGIN_API_RESPONSE.data.data;
              if(apiData.user_type === 'admin'){
                window.location = "https://dev-admin.icore.uk/login/"+apiData.token
              }
              if(apiData.user_type === 'faculty'){
                window.location = "https://dev-faculty.icore.uk/login/"+apiData.token
              }
              if(apiData.user_type === 'student'){
                localStorage.setItem('sis_user_data', JSON.stringify(LOGIN_API_RESPONSE.data.data));
                window.location.reload();
              }
              if(apiData.user_type === 'self_registration'){
                localStorage.setItem('sis_user_data', JSON.stringify(LOGIN_API_RESPONSE.data.data));
                window.location.reload();
                // window.location = "/student-online-enrollment"+apiData.token;
              }
             
         }else{
            set_errors(LOGIN_API_RESPONSE.data.errors);
            set_loader(false);
         }
    }
    return (
        <div className='login-box'>
            <div className='login-box-head'>
                <h4>Lincoln University - Student Information System</h4>
                <h2>Login</h2>
                {errors?.try && <><span style={{color:"red"}}>{errors?.try[0]}</span></>}
                {errors?.catch && <><span style={{color:"red"}}>{errors?.catch[0]}</span></>}
            </div>
            <div className='login-box-body'>
                <div className='input-box'>
                    <Input placeholder="Email" value={email} onChange={(e) => set_email(e.target.value)} autoComplete={"false"} />
                    {errors?.email && <><span style={{color:"red"}}>{errors?.email[0]}</span></>}
                </div>
                <div className='input-box'>
                    <Input.Password placeholder="Password" autoComplete="new-password" value={password} onChange={(e) => set_password(e.target.value)} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                     {errors?.password && <><span style={{color:"red"}}>{errors?.password[0]}</span></>}
                </div>
                <Checkbox checked={remember} onChange={toggleChecked}> Remember me</Checkbox>
                <div className='input-box' style={{ textAlign: "left" }}>
                    {loader ? <>
                        <Button type="primary"><Spin indicator={<LoadingOutlined style={{fontSize:'12px', color:"#fff", marginRight:"5px"}} />} /> Login</Button>
                    </> : <>
                    <Button type="primary" onClick={login}>Login</Button>
                    
                    </>}
                    <span style={{ float:"right", cursor:"pointer" }} onClick={() => navigate('/reset-password')}>Forgot Password</span>
                </div>
            </div>
        </div>
    );
};

export default Login;